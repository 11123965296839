// Main variables overrides for UI framework

// For more information about how to use these variables:
// https://material.io/develop/web/docs/theming/

// For more information on explaining colors
// https://material.io/design/color/the-color-system.html#color-theme-creation

// The theme primary color.
$mdc-theme-primary: #36444d;

// The theme secondary color.
$mdc-theme-secondary: #a83182;

//The theme surface color.
//$mdc-theme-surface: #000;

//The theme background color.
//$mdc-theme-background: #FFF;
